import React from "react";
import { Button, styled, CircularProgress } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";

interface Props {
  onClick: () => void;
  label: string;
  variant?: "primary" | "secondary" | "danger";
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: "button" | "reset" | "submit";
  dataTestId: string;
}

const CommonButton = (props: Props) => {
  const {
    label,
    variant = "primary",
    className = "",
    isLoading = false,
    isDisabled = false,
    type = "button",
    onClick,
    dataTestId
  } = props;

  const returnType = () => {
    switch (variant) {
      case "primary":
        return "primary-button";
      case "secondary":
        return "secondary-button";
      case "danger":
        return "danger-button";
    }
  };

  const returnCircularColor = () => {
    switch (variant) {
      case "primary":
        return "white-circular";
      case "secondary":
        return "purpale-circular";
      case "danger":
        return "danger-circular";
    }
  };

  return (
    <ButtonComponent
      data-test-id={dataTestId}
      onClick={onClick}
      className={returnType() + " " + className + " " + `${isDisabled ? "disabled" : ""}`}
      disabled={isLoading || isDisabled}
      type={type}
    >
      {!isLoading ? (
        label
      ) : (
        <CircularProgress size={22} className={returnCircularColor()} />
      )}
    </ButtonComponent>
  );
};

const ButtonComponent = styled(Button)({
  "&.primary-button": {
    width: "100%",
    padding: "10px 16px",
    borderRadius: 8,
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: colors().white,
    backgroundColor: colors().majorelleBlue,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: colors().buttonHover,
    },
  },
  "&.secondary-button": {
    width: "100%",
    padding: "10px 16px",
    borderRadius: 8,
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: colors().majorelleBlue,
    backgroundColor: colors().white,
    border: `1px solid ${colors().majorelleBlue}`,
    textTransform: "capitalize",
  },
  "&.danger-button": {
    width: "100%",
    padding: "10px 16px",
    borderRadius: 8,
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: colors().maximumRed,
    backgroundColor: colors().deleteButtonBg,
    border: `1px solid transparent`,
    textTransform: "capitalize",
  },
  "& .white-circular": {
    color: colors().white,
  },
  "& .purpale-circular": {
    color: colors().majorelleBlue,
  },
  "& .danger-circular": {
    color: colors().maximumRed,
  },
  "&.MuiButton-root.Mui-disabled.primary-button": {
    backgroundColor: colors().majorelleBlue,
  },
  "&.MuiButton-root.Mui-disabled.secondary-button": {
    backgroundColor: colors().white,
  },
  "&.MuiButton-root.Mui-disabled.danger-button": {
    backgroundColor: colors().deleteButtonBg,
  },
  "&.MuiButton-root.Mui-disabled.disabled": {
    backgroundColor: colors().disabledColor,
    border: `1px solid transparent`,
    color: colors().white
  },
});

export default CommonButton;
