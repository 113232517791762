export const userProfile = require("../assets/user-profile.png");
export const multiIcon = require("../assets/multi_icon.png");
export const teamIcon = require("../assets/teamicon.svg");
export const chatIcon = require("../assets/chaticon.svg");
export const workspaceIcon = require("../assets/workspace.svg");
export const dashboardIcon = require("../assets/dashboard.svg");
export const logoText = require("../assets/logo_text.png");
export const avatar = require("../assets/avatar.png");
export const neeveActiveLogo = require("../assets/neeve-active.svg");
export const neeveInactiveLogo = require("../assets/neeve-inactive.svg");

