// Customizable Area Start
import React from "react";
import AiQueryChatController, { Props, configJSON } from "./AiQueryChatController.web";
import { Box, Button, createTheme, CssBaseline, IconButton, InputAdornment, styled, TextField, ThemeProvider, Typography } from "@material-ui/core";
import { doubleRightIcon, logoBlack, submitIcon } from "./assets";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const theme = createTheme({
  palette: {
    primary: {
      main: "#684EF3",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontSize: "24px",
      lineHeight: "34px"
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize",
        borderRadius: "8px",
        border: "none",
        fontSize: "16px",
        lineHeight: "24px",
        maxHeight: "32px"
      },
      // disabled: {
      //   background: "#94A3B8",
      // }
    }
  }
});

// Customizable Area End

export default class AiQueryChat extends AiQueryChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper>

          <Box className="chat__header">
            <img src={logoBlack} alt="logo" className="logo" />
            <Typography variant="h2" component="span" className="title">{configJSON.neeveBtn}</Typography>
            <IconButton data-test-id="close-chat" onClick={this.props.handleDrawerClose}>
              <img src={doubleRightIcon} alt="arrow" className="close__btn" />
            </IconButton>
          </Box>

          <Box className="chat__body">
            
            <Box className="chat__wrapper">
              {
                this.testingMessages.map(message => (
                  <Box key={message.messageId} className={`message__wrapper ${message.sender == 'ME' ? "me" : "ai"}`}>
                    <Typography>{message.message}</Typography>
                  </Box>
                ))
              }
            </Box>

          </Box>

          <Box className="chat__footer">

            <TextField
              variant="outlined"
              fullWidth
              multiline
              className="chat__input"
              placeholder={configJSON.queryPlaceHolder}
              minRows={4}
              maxRows={6}
              value={this.state.queryInput}
              onChange={this.onQueryChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" disabled={!(this.state.queryInput.trim())} color="primary" className="submit__btn">
                      <img src={submitIcon} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />

            <Box className="button__wrapper">
              <Button color="primary" variant="contained" startIcon={<PlayArrowIcon />}>
                {configJSON.generate}
              </Button>
              <Button variant="text" color="primary" className="start__new__query" onClick={this.props.toggleNewQueryDialog}>
                {configJSON.newQuery}
              </Button>
            </Box>
          </Box>

        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: "#F4F3FB",
  "& .chat__header": {
    maxHeight: "68px",
    backgroundColor: "#D9D1EF",
    padding: "12px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .logo": {
      marginRight: "10px",
    },
    "& .title": {
      flex: 1,
      fontWeight: 400
    },
    "& .close__btn": {
      paddingRight: 0
    }
  },
  "& .chat__body": {
    flex: 1,
    padding: "20px 24px 0 24px",
    display: "flex",
    overflowY: "auto",
    "& .chat__wrapper": {
      border: "solid 1px #E2E8F0",
      borderRadius: "16px",
      background: "#FFF",
      flex: 1,
      overflow: "hidden",
      overflowY: "auto",
      padding: "16px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      "& .message__wrapper": {
        border: "none",
        marginRight: "20px",
        maxWidth: "336px",
        "&.me": {
          marginRight: "unset",
          borderRadius: "12px 12px 0 12px",
          padding: "12px",
          marginLeft: "auto",
          background: "#F4F3FB"
        }
      } 
    }
  },
  "& .chat__footer": {
    padding: "8px 20px 24px",
    "& .button__wrapper": {
      display: "flex",
      justifyContent: "space-between",
      "& .start__new__query": {
        textTransform: "unset",
        paddingRight: 0
      }
    },
    "& .chat__input": {
      marginBottom: "20px",
      "& > div": {
        background: "#FFF",
        border: "solid 1px #E2E8F0",
        padding: "16px",
        borderRadius: "16px",
        alignItems: "start",
        "& > div": {
          marginTop: "22px",
          "& .submit__btn": {
            height: "44px",
            width: "44px",
            borderRadius: "8px",
            border: "none",
            maxHeight: "unset",
            minWidth: "unset",
            "&:disabled": {
              background: "#94A3B8"
            }
          }
        }
      },
    },
  }
});
// Customizable Area End