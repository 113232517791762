// Customizable Area Start
import React from "react";
import ResultsController, { Props } from "./ResultsController.web";
import {
  Box,
  createTheme,
  CssBaseline,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#4768BE",
    },
    primary: {
      main: "#2EAB65",
    },
    background: {
      default: "#F6F5F9",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    h1: {
      lineHeight: 1.25,
      fontSize: "26px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

// Customizable Area End

export default class Results extends ResultsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  rows = [
    {
      resultsId: 1,
      category: "Business Insurance",
      description:
        "General liability insurance: Covers third-party claims for bodily injury or property damage. Professional liability (errors and omissions) insurance: Protects against claims related to professional services. Workers’ compensation insurance: Provides benefits to employees injured on the job.",
      amount: "$50,000/year",
    },
    {
      resultsId: 2,
      category: "Commercial Property",
      description:
        "Property insurance: Covers physical assets (buildings, equipment, inventory) against damage or loss. Business interruption insurance: Helps cover lost income due to a covered event (e.g., fire, natural disaster).",
      amount: "$2 million (property coverage) $500,000 (business interruption)",
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper>
          <TableContainer component={Box}>
            <Table>
              <TableHead className="header">
                <TableRow>
                  <TableCell className="number">#</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount (USD)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table__body">
                {this.rows.map((row) => (
                  <TableRow key={row.resultsId}>
                    <TableCell className="number" scope="row">{row.resultsId}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  "& .header": {
    maxHeight: "53px",
    "& th": {
      fontWeight: 700,
    },
  },
  "& .table__body": {
    "& td": {
      verticalAlign: "top",
      minWidth: "176px",
      "@media (min-width: 1100px)": {
        minWidth: "130px",
      }      
    },
  },
  "& .number": {
    minWidth: "unset !important",
    width: "16px",
    paddingLeft: "24px"
  },
});
// Customizable Area End
