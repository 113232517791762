// Customizable Area Start
import React from "react";
import ConfirmationDialogController, { configJSON } from "./ConfirmationDialogController.web";
import { Box, Checkbox, Dialog, DialogContent, FormControlLabel, Typography, styled } from "@material-ui/core";

export class ConfirmationDialog extends ConfirmationDialogController {
    render() {
        const { isDialogOpen, } = this.props
        let disableButtonStyle = !this.state.agreed ? {color: "white", backgroundColor: "#94A3B8" } : {}
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0, overflowX: "hidden" }}
                >
                    <div style={webStyles.textTitle}>{this.props.title}</div>
                    <div style={webStyles.textDescription} dangerouslySetInnerHTML={{ __html: this.props.description }}/>
                    {(this.props.checkConfirmationTitle && this.props.checkConfirmationTitle.trim().length > 0) && 
                        (
                            <div style={{marginLeft: "4%"}}>
                                <FormControlLabel
                                    data-test-id="checkConfirmationTitle"
                                    control={
                                    <StyledCheckbox
                                        data-test-id="check"
                                        checked={this.state.agreed}
                                        onChange={this.handleCheckboxChange}
                                        name="agreed"
                                    />
                                    }
                                    label={
                                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                        {this.props.checkConfirmationTitle}
                                    </Typography>
                                    }
            
                                    style={webStyles.checkbox}
                                />
                                {
                                    !this.state.agreed &&
                                        <Box 
                                            data-test-id="warningCheckDeleteComfirmation"
                                            sx={webStyles.box}>
                                            {configJSON.warningCheckDeleteComfirmation}
                                        </Box>
                                }
                            </div>
                        )
                    }
                    <div style={webStyles.viewButton}>
                        <div
                            data-test-id="cancelButton"
                            onClick={this.closeDialog}
                            style={webStyles.cancelButton}
                        >
                            {this.props.titleCancelButton}
                        </div>
                        <div
                            data-test-id="okButton"
                            onClick={this.okClick}
                            style={{...webStyles.okButton, ...disableButtonStyle } }
                        >
                            {this.props.titleOkButton}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    checkbox: {
        width: '100%',
        lineHeight: "21px",
        fontSize: '14px',
        fontFamily: 'DM Sans',
        color: '#30353B',
        marginBottom: '2px',
        alignItems: 'center',
        marginTop: "16px"
    },
    box: {
        color: '#DC2626',
        fontSize: '12px',
        lineHeight: "18px",
        fontFamily: 'DM Sans',
        fontWeight: 400, 
        marginLeft: "30px",
        padding: "0px 6rem 5px 0px",
        marginBottom: "8px"
    },
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        maxHeight: "50%",
        backgroundColor: "#fff"
    },
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        padding: "24px 32px",
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff"
    } as React.CSSProperties,
    textDescription: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "0 32px",
        marginTop: "24px"
    },
    viewButton: {
        display: "flex",
        gap: "16px",
        marginTop: "24px",
        marginBottom: "32px",
        padding: "0 32px"
    },
    cancelButton: {
        borderRadius: "8px",
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#fff",
        border: "1px solid #684EF3",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    } as React.CSSProperties,
    okButton: {
        borderRadius: "8px",
        color: "#ff0000",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#FEE2E2",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    } as React.CSSProperties,
}

export default ConfirmationDialog;
export const StyledCheckbox = styled(Checkbox)(() => ({
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    border: '1px',
    padding: '0',
    marginLeft: '15px',
    "& .MuiSvgIcon-root": {
      color: '#CDD7E6',
    },
    "&.Mui-checked .MuiSvgIcon-root": {
      color: 'black', 
      backgroundColor: 'white',
    }
  }));
// Customizable Area End