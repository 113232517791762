// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  jsonData: object
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class JsonController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      jsonData: {
          data: [
            {
              Index: 1,
              "Data Category": "Business Insurance",
              Description:
                "- General liability insurance: Covers third-party claims for bodily injury or property damage. - Professional liability (errors and omissions) insurance: Protects against claims related to professional services. - Workers’ compensation insurance: Provides benefits to employees injured on the job.",
              "Amount (USD)": "50,000/year",
            },
            {
              Index: 2,
              "Data Category": "Commercial Property",
              Description:
                "- Property insurance: Covers physical assets (buildings, equipment, inventory) against damage or loss. - Business interruption insurance: Helps cover lost income due to a covered event (e.g., fire, natural disaster).",
              "Amount (USD)": "$2 million (property coverage) $500,000 (business interruption)",
            },
            {
              Index: 3,
              "Data Category": "Leased Equipment",
              Description:
                "- Equipment lease agreements: Specify terms for leasing machinery, vehicles, or technology. - Insurance requirements: Leased equipment may need coverage against damage, theft, or breakdowns.",
              "Amount (USD)": "Varies based on equipment value",
            },
            {
              Index: 4,
              "Data Category": "Vehicle Leases",
              Description:
                "- Business car leases: Leasing vehicles for company use. - Motor vehicle insurance: Covers leased cars under the company’s policy. - Fully comprehensive insurance: Protects against damage, theft, and more.",
              "Amount (USD)": "$1,200/month per leased car",
            },
          ],
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}