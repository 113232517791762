// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../../packages/framework/src/Utilities";
import { Message } from "../../../../packages/framework/src/Message";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import moment from "moment";
import * as Yup from "yup";

export const configJSON = require("./config");

const defaultStartDate = new Date()
defaultStartDate.setHours(9, 0)

const defaultEndDate = new Date()
defaultEndDate.setHours(17, 0)

export interface UserInfoResponse {
    id: string
    attributes: {
        email: string
        first_name: string
        last_name: string
        company: string
        position: string
        bio: string
        displayed_name: string
        alternate_email: string
        time_zone: string
        working_hours_from: string
        working_hours_to: string
        profile_photo: string | null
        active: boolean
    }
}

export interface WorkspaceNoti {
    name: string;
    attributes: {
        chat_message: boolean
        comments: boolean
        mentions: boolean
        invites: boolean
        access_requests: boolean
        property_changes: boolean
    }
}

export interface Props {
    navigation: any;
    id: string;
    showProfileFunc?:any;
    classes: Record<string, string>
}

export interface S {
    selectedFile: File | null,
    previewUrl: string | ArrayBuffer | null,
    showPopup: boolean,
    showText: boolean,
    toLogout: boolean;
    showPage: boolean;
    secondPage: boolean;
    firstPage: boolean;
    thirdPage: boolean;
    currentPage: 'general' | 'notifications' | 'accounts';
    isNotificationsEnabled: boolean;
    showModal: boolean;
    closeModal: boolean;
    showLogoutModal: boolean;
    closeLogoutModal: boolean;
    showEditModal:boolean;
    showChangeEmail:boolean;
    currentUser?: UserInfoResponse
    loading: boolean
    emailValue: string
    bioValue: string
    startDate: Date
    endDate: Date
    timezone: string
    isTutorialEnabled: boolean
    active: string
    showButtonDialog: boolean
    isSnackbarOpen: boolean
    snackbarContent: string
    displayNameValue: string
    accountId: string
    anchorEl: Element | ((element: Element) => Element) | null | undefined
    showNotiList: boolean
    listWorkspaceNoti: Array<WorkspaceNoti>;
    openHelpSupportDialogue: boolean;
}

export interface SS {
    id: any;
}

export default class UserProfileBasicControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    getCurrentUserId: string = ""
    updateCurrentUserId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            selectedFile: null,
            previewUrl: null,
            showPopup: false,
            showText: false,
            toLogout: false,
            showPage: false,
            secondPage: false,
            firstPage: true,
            thirdPage: false,
            currentPage: 'general',
            isNotificationsEnabled: false,
            showModal: false,
            closeModal: false,
            showLogoutModal: false,
            closeLogoutModal: true,
            showEditModal: false,
            showChangeEmail: false,
            currentUser: undefined,
            loading: false,
            displayNameValue: "",
            bioValue: "",
            emailValue: "",
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? "Asia/Kolkata",
            isTutorialEnabled: false,
            active: "Active",
            showButtonDialog: false,
            isSnackbarOpen: false,
            snackbarContent: "",
            accountId: "",
            anchorEl: null,
            showNotiList: false,
            listWorkspaceNoti: [],
            openHelpSupportDialogue: false
        };
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.data) {
                if (apiRequestCallId === this.getCurrentUserId) {
                    this.handleDataUser(responseJson.data)
                }
                if (apiRequestCallId === this.updateCurrentUserId) {
                    this.setState({ loading: false, showButtonDialog: false, isSnackbarOpen: true, snackbarContent: "Changes have been saved" })
                }
            } else {
                this.handleError(message)
            }
        }
        // Customizable Area End
    }
    handleClose = () => this.setState({ isSnackbarOpen: false, snackbarContent: "" })

    handleDataUser = (userInfo: UserInfoResponse) => {
        this.setState({
            currentUser: userInfo,
            bioValue: userInfo?.attributes?.bio ?? "jenny.wilson@example.com",
            displayNameValue: userInfo?.attributes?.displayed_name ?? "Jane Cooper",
            emailValue: userInfo?.attributes?.alternate_email ?? "jenny.wilson@example.com",
            timezone: userInfo?.attributes.time_zone ?? Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? "GMT",
            startDate: userInfo?.attributes.working_hours_from ? new Date(`01/01/1970 ${userInfo?.attributes.working_hours_from}`) : defaultStartDate, 
            endDate: userInfo?.attributes.working_hours_to ? new Date(`01/01/1970 ${userInfo?.attributes.working_hours_to}`) : defaultEndDate,
            active: userInfo?.attributes?.active ? "Active" : "Inactive",
            loading: false
        })
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getCurrentUser()
        this.setState({
            listWorkspaceNoti: [
                {
                    name: "LT Group Workspace",
                    attributes: {
                        access_requests: true,
                        mentions: true,
                        invites: true,
                        chat_message: true,
                        comments: true,
                        property_changes: true,
                    }
                }
            ]
        })
    }

    onChangeDisplayName = (bio: string) => {
        this.setState({displayNameValue: bio})
    }

    onChangeBio = (bio: string) => {
        this.setState({ bioValue: bio })
    }

    onChangeEmail = (email: string) => {
        this.setState({ emailValue: email })
    }

    handleError = (message: Message) => {
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if ((responseJson?.errors?.[0].token === "Token has Expired" || responseJson?.errors?.[0].token === "Invalid token") && (
            apiRequestCallId === this.getCurrentUserId 
        )) {
            this.onOkLogOutDialog()
        }
    }

    reloadProfile = () => {
        this.getCurrentUser()
    }

    getCurrentUser = async () => {
        const token = await getStorageData("authToken")
        const accountId = await getStorageData("account_id");
        this.setState({ loading: true, accountId })
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getCurrentUserId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${configJSON.getCurrentUserAPI}/${accountId}`
        });
    }

    onUpdateProfile = async () => {
        this.setState({ loading: true })
        const account_id = await getStorageData("account_id");
        const token = await getStorageData("authToken")

        const createBulkUploadMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.updateCurrentUserId = createBulkUploadMsg.messageId;

        createBulkUploadMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCurrentUserAPI}/${account_id}`
        );

        const header = {
            token,
        }

        const formData = new FormData();
        formData.append("data[attributes][displayed_name]", this.state.displayNameValue);
        formData.append("data[attributes][bio]", this.state.bioValue);
        formData.append("data[attributes][alternate_email]", this.state.emailValue);
        formData.append("data[attributes][time_zone]", this.state.timezone);
        formData.append("data[attributes][working_hours_from]", moment(this.state.startDate).format("HH:mm"));
        formData.append("data[attributes][working_hours_to]", moment(this.state.endDate).format("HH:mm"));
        formData.append("data[attributes][active]", String(this.state.active === "Active"));
        createBulkUploadMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        createBulkUploadMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        createBulkUploadMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

        runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
    }

    showPop = () => {
        if (this.state.previewUrl != null) {
            this.setState({ showPopup: true, showText: true })
        }
    }

    removePop = () => {
        this.setState({ showPopup: false, showText: false })
    }

    handleOnloadEnd = (reader: FileReader) => {
        this.setState({ previewUrl: reader.result as string });
    };

    handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            this.setState({previewUrl:URL.createObjectURL(file)})
        }
    };

    handleFileRemove = () => {
        this.setState({ previewUrl: null });
    };

    handleNotificationsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isNotificationsEnabled: event.target.checked, 
        });
    };

    handlePageChange = (page: 'general' | 'notifications' | 'accounts') => {
        this.setState({ currentPage: page });
    };

    handleBackToModal = () => {
        this.setState({showModal:!this.state.showModal})
    }

    handleLogoutModal = () => {
        this.setState({showLogoutModal:!this.state.showLogoutModal})
    }

    handleEditShowProfile=()=>{
        this.setState({showEditModal:!this.state.showEditModal})
    }

    handleEditShowButton = () => {
        this.setState({showButtonDialog: true})
    }

    showChangeEmail=()=>{
      this.setState({showChangeEmail:!this.state.showChangeEmail})
    }

    handleTutorialToggle = () => {
        this.setState({ isTutorialEnabled: !this.state.isTutorialEnabled })
    }

    onOkLogOutDialog = () => {
        removeStorageData("account_id")
        removeStorageData("authToken")
        const msgType: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msgType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock"
        );
        msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgType);
    }

    onChangeStartDate = (date: Date | null) => {
        this.setState({ startDate: date || new Date() })
    }

    onChangeEndDate = (date: Date | null) => {
        this.setState({ endDate: date || new Date(),  })
    }

    onChangeTimezone = (timezone: { value: string, label: string, abbrev: string, altName: string }) => {
        this.setState({ timezone: timezone.value })
    }

    handleChangeActive = (event: any) => {
        this.setState({ active: event.target.value,  });
    }

    onDiscardChanges = () => {
        this.setState({
            bioValue: this.state.currentUser?.attributes?.bio ?? "",
            displayNameValue: this.state.currentUser?.attributes?.displayed_name ?? "",
            emailValue: this.state.currentUser?.attributes?.alternate_email ?? "",
            timezone: this.state.currentUser?.attributes.time_zone ?? Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? "GMT",
            startDate: this.state.currentUser?.attributes.working_hours_from ? new Date(`01/01/1970 ${this.state.currentUser?.attributes.working_hours_from}`) : defaultStartDate, 
            endDate: this.state.currentUser?.attributes.working_hours_to ? new Date(`01/01/1970 ${this.state.currentUser?.attributes.working_hours_to}`) : defaultEndDate,
            showButtonDialog: false
        })
    }

    onUpdateChanges = () => {
        this.onUpdateProfile()
    }

    onOkDeleteDialog = () => {

    }

    handleNotificationsListToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            showNotiList: event.target.checked, 
        });
    };

    toggleHelpSupportDialogue = () => {
        this.setState({
            openHelpSupportDialogue: !this.state.openHelpSupportDialogue, 
        });
    };

    editProfileValidationSchema = Yup.object({
        displayName: Yup.string()
                    .required('Name is required'),
        bio: Yup.string()
                    .required('Bio is required'),
        email: Yup.string()
                    .email('Incorrect email format')
                    .required('Email is required'),
        timeZone: Yup.string()
                    .required('TimeZone is required'),
        startDate: Yup.string()
                    .required('Start time is required'),
        endDate: Yup.string()
                    .required('End time is required') 
                    .test(
                        "is-greater",
                        "End time must be greater than start time",
                        function (value) {
                          const { startDate } = this.parent;
                          return startDate && value ? value > startDate : true;
                        }
                      ),
    })

    changeEmailValidationSchema = Yup.object({
        email: Yup.string()
                    .email('Invalid email address')
                    .required('Email is required'),
        confirmEmail: Yup.string()
                    .email('Invalid email address')
                    .required('Confirm Email is required')
                    .oneOf([Yup.ref('email'), null], "Emails doesn't match")
    })

    verifyEmailOTPValidationSchema = Yup.object({
        otpNumber: Yup.string()
                    .required('Otp is required')
                    .length(5, 'OTP must be exactly 5 digits'),
    })
};
// Customizable Area End
