import { SelectOption } from "./interface";

export enum UploadOptions {
  NoAction = "NO_ACTION",
  AIProcessing = "AI_PROCESSING",
  GreekLegalDocument = "GREEK_LEGAL_DOCUMENT",
}

export const uploadFileOptions: SelectOption[] = [
  {
    label: "Upload file with no action required",
    value: UploadOptions.NoAction,
  },
  {
    label: "Upload file with AI processing",
    value: UploadOptions.AIProcessing,
  },
  {
    label: "Upload Greek Document with AI processing",
    value: UploadOptions.GreekLegalDocument,
  },
];
