// Customizable Area Start
import React from "react";
import {
  Box,
  createTheme,
  CssBaseline,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import NeeveDashboardController, {
  Props,
  configJSON,
} from "./NeeveDashboardController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { notificationIcon, searchIcon, workspaceIcon } from "./assets";
import { colors } from "../../utilities/src/Colors";
import { Autocomplete } from "@material-ui/lab";
import CustomInput from "../../../components/src/common/CustomInput.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#684EF3",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#30353B",
      secondary: "#14101E",
    },
    background: {
      default: "#F6F5F9",
      paper: "#FFF",
    },
  },
  typography: {
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "24px",
      lineHeight: "34px",
    },
    body1: {
      fontSize: "14px",
      lineHeight: "23px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
});

// Customizable Area End

export default class NeeveDashboard extends NeeveDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  dummyData = [
    {
      chatId: 1,
      title:
        "Can you provide an overview of insurance responsibilities for landlords and tenants in different cities, ensuring compliance with local regulations and financial stability?",
      workspace: "LT Workspace",
      date: "09/12/2024",
    },
    {
      chatId: 2,
      title:
        "Evaluate my income sources and provide recommendations for diversification to ensure financial growth, security, and a well-balanced investment strategy.",
      workspace: "Leases Workspace",
      date: "09/12/2024",
    },
    {
      chatId: 3,
      title:
        "Analyze my budgeting effectiveness and suggest areas where I can save more efficiently while maintaining my lifestyle and long-term financial goals.",
      workspace: "Horizon Sales",
      date: "09/12/2024",
    },
    {
      chatId: 4,
      title:
        "Provide a summary of my tax returns and highlight any potential deductions I might have missed to optimize my tax savings for the financial year.",
      workspace: "Nexus Projects",
      date: "09/12/2024",
    },
    {
      chatId: 5,
      title:
        "Evaluate my income sources within October 2024 and identify new opportunities for increasing revenue streams through smart investments and passive income strategies.",
      workspace: "Pinnacle Marketing",
      date: "09/12/2024",
    },
    {
      chatId: 6,
      title:
        "Compare my credit card statements over the last quarter to identify any unusual spending patterns, fraudulent transactions, or areas where I can cut back.",
      workspace: "Echo Support",
      date: "09/12/2024",
    },
    {
      chatId: 7,
      title:
        "Can you provide an overview of insurance responsibilities for landlords and tenants in various regions, covering liability, property damage, and legal obligations?",
      workspace: "Developers",
      date: "09/12/2024",
    },
  ];

  shortenText = (text: string, limit = 90): string => {
    return text.length > limit ? text.slice(0, limit) + "..." : text;
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper>
          <Box className="sidebar__container">
            <NavigationMenu navigation={this.props.navigation} id="neeve" />
          </Box>

          <Box className="main__container">
            <Box component="header" className="header">
              <Typography variant="h1" className="header__title">
                {configJSON.neeveTitle}
              </Typography>
              <IconButton>
                <img src={notificationIcon} alt="icon" />
              </IconButton>
            </Box>

            <Box className="content">
              <Box className="search__section">
                <CustomAutoComplete
                  options={[]}
                  // value={""}
                  // onChange={}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <CustomInput
                      {...params}
                      name="search"
                      placeholder="Search"
                      // onChange={(event: any) => console.log("Log: ", event?.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="end">
                            <img src={searchIcon} />
                          </InputAdornment>
                        ),
                        endAdornment: <></>,
                      }}
                    />
                  )}
                />
              </Box>

              <Box className="listing__wrapper">
                {this.dummyData.map((query) => (
                  <Box key={query.chatId}>
                    <Paper
                      elevation={0}
                      className="chat__wrapper"
                      data-test-id="query"
                      onClick={() => this.redirectTo(query.chatId)}
                    >
                      <Typography>{this.shortenText(query.title)}</Typography>
                      <Divider className="divider" />
                      <Box className="chat__footer">
                        <Box className="workspace__name">
                          <img
                            className="icon"
                            src={workspaceIcon}
                            alt="icon"
                          />
                          <Typography className="" variant="body2">
                            {query.workspace}
                          </Typography>
                        </Box>
                        <Typography variant="body2">
                          {moment(query.date).format("DD.MM.YY")}
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomAutoComplete = styled(Autocomplete)({
  "& .MuiFormControl-root": {
    minHeight: "unset",
    "& .MuiInputBase-root": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "2px !important",
    },
  },
});

const BoxWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100vh",
  "& .sidebar__container": {
    position: "sticky",
  },
  "& .main__container": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& .header": {
      padding: "23px 40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "solid 1px #E2E8F0",
      "& .header__title": {
        color: "#14101E",
      },
    },
    "& .content": {
      flex: 1,
      overflowY: "auto",
      padding: "18px 40px",
      "& .search__section": {
        maxWidth: "288px",
        marginBottom: "20px"
      },
      "& .listing__wrapper": {
        display: "grid",
        gap: "20px",
        gridTemplateColumns: "auto auto auto",
        "& .chat__wrapper": {
          cursor: "pointer",
          padding: "16px 24px",
          borderRadius: "16px",
          border: "none",
          "& .divider": {
            backgroundColor: "#E2E8F0",
            marginTop: "8px",
            marginBottom: "16px",
          },
          "& .chat__footer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .workspace__name": {
              maxHeight: "24px",
              padding: "4px 8px",
              backgroundColor: colors().cultured,
              border: "none",
              borderRadius: "16px",
              display: "flex",
              gap: "5px",
              alignItems: "center",
            },
          },
        },
      },
    },
  },
});
// Customizable Area End
