// Customizable Area Start
import React from "react";
import JsonController, { configJSON, Props } from "./JsonController.web";
import {
  Box,
  Button,
  createTheme,
  CssBaseline,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#1D4ED8",
    },
    text: {
      primary: "#0F172A",
    },
    background: {
      default: "#F6F5F9",
    },
  },
  typography: {
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
    body1: {
      fontSize: "14px"
    }
  },
});

// Customizable Area End

export default class Json extends JsonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderJson = (data: any, isNested = false) => {
    if (typeof data === "object" && data !== null) {
      if (Array.isArray(data)) {
        return (
          <span>
            [<br />
            <Box ml="10px">
              {data.map((item, index) => (
                <div key={index}>
                  {this.renderJson(item, true)}
                  {index < data.length - 1 && ","}
                  <br />
                </div>
              ))}
            </Box>
            ]
          </span>
        );
      } else {
        return (
          <span>
            {"{"} <br />
            <Box ml="10px">
              {Object.entries(data).map(([key, value], index, array) => (
                <div key={index}>
                  <Typography component="span" color="secondary">"{key}"</Typography>:
                  {this.renderJson(value, true)}
                  {index < array.length - 1 && ","}
                  <br />
                </div>
              ))}
            </Box>
            {"}"}
          </span>
        );
      }
    } else {
      return <Typography component="span" color="textPrimary">{JSON.stringify(data)}</Typography>;
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {this.renderJson(this.state.jsonData)}
          </pre>

        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  padding: "30px 40px",
});
// Customizable Area End
