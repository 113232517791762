import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { UploadOptions } from "../../../blocks/utilities/src/constant";
import {
  FileResponse,
  FileUploadData,
  PaginationMeta,
} from "../../../blocks/utilities/src/interface";
import { makeApiMessage } from "../../../blocks/utilities/src/commonfunctions";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  actionMenuAnchor: null | HTMLElement;
  replaceFileDialogOpen: boolean;
  selectedRow: FileResponse | null;
  applyFileActionVal: string;
  isOpenDeleteDialog: boolean;
  isOpenUploadDialog: boolean;
  isOpenPreviewDialog: boolean;
  dataFiles?: {
    workspace_id: number;
    files: any;
    uploadChoice: UploadOptions | null;
  };
  showAiProcessingForm: boolean;
  fileLists: Array<FileResponse>;
  isDeleteFileLoading: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarServerity: "success" | "error" | "warning";
  mainLoading: boolean;
  newReplacedFile: File[] | null;
  totalPages: number;
  currentPage: number;
  perPage: number;
  totalResults: number;
  isFetchingData: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AiQueryHubFilesTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllWorkspaceFilesApiCallId: string = "";
  deleteWorkspaceFileApiCallId: string = "";
  uploadFileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      actionMenuAnchor: null,
      replaceFileDialogOpen: false,
      selectedRow: null,
      applyFileActionVal: UploadOptions.NoAction,
      isOpenDeleteDialog: false,
      isOpenUploadDialog: false,
      isOpenPreviewDialog: false,
      dataFiles: undefined,
      showAiProcessingForm: true,
      fileLists: [],
      isDeleteFileLoading: false,
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarServerity: "success",
      mainLoading: false,
      newReplacedFile: null,
      totalPages: 8,
      currentPage: 1,
      perPage: 6,
      totalResults: 12,
      isFetchingData: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        webResponseJson &&
        !webResponseJson.errors &&
        !webResponseJson.error
      ) {
        switch(webApiRequestCallId) {
          case this.getAllWorkspaceFilesApiCallId:
            this.handleWorkspaceFileResponse(webResponseJson);
            break;
          case this.deleteWorkspaceFileApiCallId:
            this.handleDeleteFileSuccessResp(webResponseJson);
            break;
          case this.uploadFileApiCallId:
            this.handleUploadFileSuccessResp(webResponseJson);
            break;
        }
      } else if (
        webResponseJson &&
        (webResponseJson.errors || webResponseJson.error)
      ) {
        switch(webApiRequestCallId) {
          case this.getAllWorkspaceFilesApiCallId:
            this.setState({ isFetchingData: false, fileLists: [] });
            break;
          case this.getAllWorkspaceFilesApiCallId:
            this.setState({ isDeleteFileLoading: false });
            break;
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetFilesList();
    // Customizable Area End
  }

  // Customizable Area Start
  onCloseActionMenu = () => {
    this.setState({ actionMenuAnchor: null, selectedRow: null });
  };

  handleOpenActionMenu = (
    event: React.MouseEvent<HTMLElement>,
    selectedRow: FileResponse
  ) => {
    this.setState({
      actionMenuAnchor: event.currentTarget,
      selectedRow: selectedRow,
    });
  };

  closeReplaceFileDialog = () => {
    this.setState({ replaceFileDialogOpen: false, newReplacedFile: null });
  };

  handleOpenReplaceDialog = () => {
    this.setState({ replaceFileDialogOpen: true, actionMenuAnchor: null });
  };

  onDrag = (files: File[]) => {
    this.setState({ newReplacedFile: files });
  };

  handleChangeActionVal = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ applyFileActionVal: event.target.value as string });
  };

  handleReplaceFile = () => {
    this.closeReplaceFileDialog();
  };

  handleOpenDeleteDialog = () => {
    this.setState({
      isOpenDeleteDialog: true,
      actionMenuAnchor: null,
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ isOpenDeleteDialog: false });
  };

  onCloseUploadDialog = (data?: FileUploadData) => {
    this.setState({ isOpenUploadDialog: false });
    if (data) {
      if (
        [UploadOptions.AIProcessing, UploadOptions.GreekLegalDocument].includes(
          data.uploadChoice
        )
      ) {
        this.setState({ isOpenPreviewDialog: true, dataFiles: data });
      } else {
        this.handleUploadFile(data);
      }
    }
  };

  handleClosePreviewDialog = () => {
    this.setState({
      isOpenPreviewDialog: false,
      dataFiles: undefined,
      currentPage: 1
    });
    this.handleGetFilesList();
  };

  handleOpenUploadModal = () => {
    this.setState({ isOpenUploadDialog: true });
  };

  handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage, isFetchingData: true }, () =>
      this.handleGetFilesList()
    );
  };

  handleGetFilesList = async () => {
    this.setState({ isFetchingData: true });
    const { perPage, currentPage } = this.state;
    const apiUrl = configJSON.filesUploadedByWorkspaceIdApiUrl;
    const workspaceId = this.props.navigation.getParam("id");

    const requestMessage = await makeApiMessage({
      url: apiUrl + workspaceId + `&page=${currentPage}&per_page=${perPage}`,
      method: "GET",
    });

    this.getAllWorkspaceFilesApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteFile = async () => {
    this.setState({ isDeleteFileLoading: true });

    const { selectedRow } = this.state;
    const apiUrl = configJSON.deleteWorkspaceFileApiUrl;
    const workspaceId = this.props.navigation.getParam("id");
    const body = {
      file_id: selectedRow?.id,
    };

    const requestMessage = await makeApiMessage({
      url: apiUrl + `/${workspaceId}/delete_workspace_files`,
      method: "DELETE",
      body: JSON.stringify(body),
    });

    this.deleteWorkspaceFileApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteFileSuccessResp = (response: { message: string }) => {
    this.setState(
      {
        isDeleteFileLoading: false,
        isOpenDeleteDialog: false,
        snackbarOpen: true,
        snackbarMessage: response.message,
        currentPage: 1
      },
      () => this.handleGetFilesList()
    );
  };

  handleWorkspaceFileResponse = (response: {
    data?: Array<FileResponse>;
    meta: PaginationMeta;
  }) => {
    if(response.data) {
      this.setState({
        fileLists: response.data,
        isFetchingData: false,
        totalPages: response.meta.total_pages,
        currentPage: response.meta.current_page,
        totalResults: response.meta.total_count
      });
    } else {
      this.setState({
        fileLists: [],
        isFetchingData: false,
        totalPages: 0,
        currentPage: 1,
        totalResults: 0
      });
    }
  };

  handleCloseSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  handleUploadFile = async (data: FileUploadData) => {
    const apiUrl = configJSON.uploadFileWorkspace;
    const workspaceId = this.props.navigation.getParam("id");

    const formData = new FormData();
    formData.append("workspace_id", workspaceId.toString());
    data.files.forEach((item) => {
      formData.append("files[]", item);
    });

    const requestMessage = await makeApiMessage({
      url: apiUrl,
      method: "POST",
      body: formData,
      isHeader: true,
    });

    this.uploadFileApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUploadFileSuccessResp = (response: { message: string }) => {
    this.setState(
      {
        isOpenUploadDialog: false,
        snackbarOpen: true,
        snackbarMessage: response.message,
        currentPage: 1
      },
      () => this.handleGetFilesList()
    );
  };

  handleViewFile = (selectedRow: FileResponse) => {
    const file = selectedRow?.attributes.files[0];
    const aiFileUploaded = selectedRow?.attributes.ai_file_uploaded;
    this.setState({ mainLoading: true, actionMenuAnchor: null });

    // Fetch file object
    fetch(file.url)
      .then((result) => result.blob())
      .then((blob) => {
        const fileObj = new File([blob], file.filename, { type: blob.type });

        this.setState({
          showAiProcessingForm: !aiFileUploaded,
          isOpenPreviewDialog: true,
          dataFiles: {
            files: [fileObj],
            uploadChoice: null,
            workspace_id: 0,
          },
          mainLoading: false,
        });
      })
      .catch(() => {
        this.setState({ mainLoading: false });
      });
  };
  // Customizable Area End
}
