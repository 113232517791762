// Customizable Area Start
import React from "react";
import {
  Box,
  IconButton,
  styled,
  Typography,
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import NeeveQueryController, {
  Props,
  configJSON,
} from "./NeeveQueryController.web";
import { notificationIcon, workspaceIcon } from "./assets";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import AiQueryHub from "./AiQueryHub.web";


// Customizable Area End

export default class NeeveQuery extends NeeveQueryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <StyledWrapper>
          <Box className="sidebar">
            <NavigationMenu navigation={this.props.navigation} id="neeve" />
          </Box>

          <Box className="main__container">
            <Box component="header" className="header__wrapper">
              <Box className="header__breadcrumb">
                <Typography className="label__body1" data-test-id="header-title">{configJSON.neeveTitle}</Typography>
                <IconButton className="breadcrumb__icon">
                  <ChevronRightRoundedIcon fontSize="small" />
                </IconButton>
                <Typography className="label__body1 active__workspace">
                  {configJSON.currentWorkspace}
                </Typography>
              </Box>
              <Box className="header__info">
                <Box className="workspace__info__wrapper">
                  <Box className="workspace_label">
                    <img src={workspaceIcon} alt="icon" />
                    <Typography className="label__body1 workspace__name">
                      LT Workspace
                    </Typography>
                  </Box>
                  <Typography className="label__body1 workspace__status" color="primary">
                    Open
                  </Typography>
                </Box>

                <IconButton>
                  <img
                    className="notification__icon"
                    src={notificationIcon}
                    alt="icon"
                  />
                </IconButton>
              </Box>
            </Box>

            <AiQueryHub navigation={this.props.navigation} />
          </Box>
        </StyledWrapper>
      // </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  height: "100vh",
  display: "flex",
  width: "100%",
  backgroundColor: "#F6F5F9",
  "& .sidebar": {
    position: "sticky",
  },
  "& .main__container": {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    "& .header__wrapper": {
      alignItems: "center",
      display: "flex",
      borderBottom: "solid 1px #E2E8F0",
      justifyContent: "space-between",
      padding: "23px 40px",
      height: "80px",
      "& .header__breadcrumb": {
        display: "flex",
        alignItems: "center",
        "& .breadcrumb__icon": {
          color: "#94A3B8",
        },
        "& .active__workspace": {
          fontWeight: 500,
        },
      },
    },
    "& .header__info": {
      display: "flex",
      alignItems: "center",
      gap: "14px",
      "& .workspace__info__wrapper": {
        borderRadius: "32px",
        backgroundColor: "#FFF",
        height: "40px",
        display: "flex",
        alignItems: "center",
        padding: "4px 12px 4px 4px",
        gap: "8px",
        "& .workspace_label": {
          display: "flex",
          alignItems: "center",
          padding: "6px 8px 6px 6px",
          gap: "5px",
          backgroundColor: "#F6F5F9",
          borderRadius: "16px",
          "& .workspace__name": {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#5E6671",
          },
        },
        "& .workspace__status": {
          color: "#684EF3"
        }
      },
    },
  },
  "& .label__body1": {
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "14px",
    lineHeight: "23px",
    color: "#30353B"
  },
});
// Customizable Area End
