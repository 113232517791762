Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpromptingcustomquery1";
exports.labelBodyText = "cfpromptingcustomquery1 Body";
exports.filesUploadedByWorkspaceIdApiUrl = "bx_block_workspace/workspaces/file_list?workspace_id=";
exports.deleteWorkspaceFileApiUrl = "bx_block_workspace/workspaces";
exports.uploadFileWorkspace = "bx_block_workspace/workspaces/upload_files";
exports.labelBodyText = "cfpromptingcustomquery 1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.versionLabel = "Version";
exports.neeveBtn = "Neeve";
exports.newQuery = "Start new query";
exports.generate = "Generate";
exports.queryPlaceHolder = "Add details which make your request more precise. Use @ to find tables and columns.";
exports.HistoryTabString = {
  delteDialogueTitle: "Are you sure you want to delete query?",
  deleteBtn : "Delete query",
  cancelBtn: "Cancel",

}

exports.filesTabString = {
  viewFile: "View File",
  replaceFile: "Replace file",
  deleteFile: "Delete file"
}
exports.downloadingSettings = "Downloading settings";
exports.shareDialogTitle = "Share result as a file";
exports.chooseSourceTitle = "Choose source for sharing";
exports.shareWithChat = "Share within a chat";
exports.shareWithEmail = "Send to an email";
exports.neeveTitle = "Neeve";
exports.currentWorkspace = "AI Query Hub: LT Workspace";
// Customizable Area End