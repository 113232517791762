import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  CircularProgress
} from "@material-ui/core";
import CommonButton from "./CommonButton.web";


interface DialgoueProps {
  open: boolean;
  title: string;
  body: React.ReactNode;
  deleteBtnText: string;
  cancelBtnText: string;
  handleCancel: Function;
  handleDelete: Function;
  isLoading?: boolean;
}

const CommonDeleteDialogue = (props: DialgoueProps) => {
  const {
    open,
    title,
    body,
    deleteBtnText,
    cancelBtnText,
    handleDelete,
    handleCancel,
    isLoading
  } = props;

  const handleCloseDialog = () => {
    if(isLoading) {
      return;
    } else {
      handleCancel();
    };
  };

  return (
    <>
      <StyledDialogue
        data-test-id="delete-dialogue"
        maxWidth={"xs"}
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <CommonButton
            onClick={handleCloseDialog}
            variant="secondary"
            label={cancelBtnText}
            dataTestId={"cancelBtn"}
          />
          <CommonButton
            onClick={() => handleDelete()}
            variant="danger"
            label={deleteBtnText}
            dataTestId={"confirmDeleteBtn"}
            isLoading={isLoading}
          />
        </DialogActions>
      </StyledDialogue>
    </>
  );
};

const StyledDialogue = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "100%",
    maxWidth: "496px",
  },
  "& .MuiDialog-paper": {
    borderRadius: 24,
    boxShadow: "0px 24px 48px -12px #684EF333",
  },
  "& .MuiDialogTitle-root": {
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: 400,
    color: "#14101E",
    borderBottom: "1px solid #E2E8F0",
    fontFamily: "DM Sans",
  },
  "& .MuiDialogContent-root": {
    fontSize: "16px",
    color: "#14101E",
    backgroundColor: "white",
    fontFamily: "DM Sans",
  },
  "& .MuiDialogActions-root": {
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 32px",
    fontFamily: "DM Sans",
  },
  "& .MuiButton-root": {
    fontSize: "16px",
    fontWeight: 400,
    padding: "10px 16px",
    textTransform: "none",
    width: "100%",
    fontFamily: "DM Sans",
    height: "44px",
    "& .MuiButton-label": {
      height: "24px"
    },
  },
  "& .cancelBtn": {
    color: "#684EF3",
    backgroundColor: "white",
    border: "1px solid #684EF3",
  },
  "& .deleteBtn": {
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
  },
  "& .MuiPaper-elevation24": {
    boxShadow: "none",
  },
  "& .MuiDialog-paperWidthXs": {
    maxWidth: "496px",
  },
  "& .MuiTypography-body1": {
    fontSize: "16px",
    fontFamily: "DM Sans",
  },
  "& .MuiTypography-h6": {
    fontSize: "24px",
    fontFamily: "DM Sans",
  },
});

export default CommonDeleteDialogue;
