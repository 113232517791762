import React from "react";
import { Box, styled, Typography } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import { uploadSvgIcon } from "./assets";

interface Props {
  onBrowse: () => void;
}

const DragAndDropBox = (props: Props) => {
  const { onBrowse } = props;
  return (
    <DragAndDropWrapper>
      <Box className="upload-img">
        <img src={uploadSvgIcon} alt="uploadIcon" />
      </Box>
      <Typography className="uploadText">
        Drag and drop your files or{" "}
        <span className="upload-span" data-test-id="browse-link" onClick={onBrowse}>
          Browser
        </span>
      </Typography>
    </DragAndDropWrapper>
  );
};

const DragAndDropWrapper = styled(Box)({
  padding: "30px",
  border: `2px dashed ${colors().bluePurple}`,
  backgroundColor: colors().darkPurple,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  "& .upload-img": {
    width: "48px",
    height: "48px",
    minWidth: "48px",
    minHeight: "48px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  "& .uploadText": {
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    textAlign: "center",
  },
  "& .upload-span": {
    color: colors().majorelleBlue,
    cursor: "pointer",
  },
});

export default DragAndDropBox;
