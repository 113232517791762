import React from "react";
// Customizable Area Start
import AiQueryHubFilesTabController, {
  Props,
  configJSON,
} from "./AiQueryHubFilesTabController.web";
import {
  Box,
  styled,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { avtarIcon } from "./assets";
import { colors } from "../../../blocks/utilities/src/Colors";
import {
  MoreVert,
  DescriptionOutlined,
  Cached,
  DeleteOutline,
} from "@material-ui/icons";
import DragAndDropBox from "../../../components/src/common/DragAndDropBox.web";
import ReplaceFileDialog from "../../../components/src/common/ReplaceFileDialog.web";
import TableFileView from "../../../components/src/common/TableFileView.web";
import BulkUploadDashboardDialog from "../../../blocks/dashboard/src/BulkUploadDashboardDialog.web";
import DashboardPreviewFile from "../../../blocks/dashboard/src/DashboardPreviewFile.web";
import { UploadOptions } from "../../../blocks/utilities/src/constant";
import { useStyles as dashboardStyles } from "../../dashboard/src/Dashboard.web";
import {
  getFileExtension,
  getPaginationIndex,
  renderBaseonCondition,
} from "../../../blocks/utilities/src/commonfunctions";
import moment from "moment";
import CommonDeleteDialogue from "../../../components/src/common/CommonDeleteDialogue.web";
import CommonSnackbar from "../../../components/src/common/CommonSnackbar.web";
import Loader from "../../../components/src/Loader.web";
import {
  FileInfo,
  FileResponse,
} from "../../../blocks/utilities/src/interface";
import StyledPagination from "../../../components/src/common/Pagination.web";
// Customizable Area End

// Customizable Area Start
const string = configJSON.filesTabString;
// Customizable Area End

export class AiQueryHubFilesTab extends AiQueryHubFilesTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  actionMenu = () => {
    const { actionMenuAnchor, selectedRow } = this.state;
    const open = Boolean(actionMenuAnchor);
    return (
      <StyledMenu
        data-test-id="action-menu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={actionMenuAnchor}
        open={open}
        onClose={this.onCloseActionMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          data-test-id="view-file-menu"
          onClick={() => this.handleViewFile(selectedRow as FileResponse)}
        >
          <DescriptionOutlined className="menu-icon" />
          <Typography className="menu-item">{string.viewFile}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => this.handleOpenReplaceDialog()}
          data-test-id="replace-file-menu"
        >
          <Cached className="menu-icon" />
          <Typography className="menu-item">{string.replaceFile}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => this.handleOpenDeleteDialog()}
          data-test-id="delete-file-menu"
        >
          <DeleteOutline className="menu-icon delete-menu-icon" />
          <Typography className="menu-item delete-menu-item">
            {string.deleteFile}
          </Typography>
        </MenuItem>
      </StyledMenu>
    );
  };

  replaceFileDialog = () => {
    const {
      replaceFileDialogOpen,
      applyFileActionVal,
      selectedRow,
      newReplacedFile,
    } = this.state;
    return (
      <ReplaceFileDialog
        open={replaceFileDialogOpen}
        onClose={this.closeReplaceFileDialog}
        viewFile={selectedRow?.attributes.files as FileInfo[]}
        newReplacedFile={newReplacedFile}
        onDrag={this.onDrag}
        applyFileActionVal={applyFileActionVal}
        onChangeAction={(
          event: React.ChangeEvent<{ name?: string; value: unknown }>
        ) => this.handleChangeActionVal(event)}
        onReplaceFile={this.handleReplaceFile}
      />
    );
  };

  renderDeleteDialog = () => {
    const { isOpenDeleteDialog, isDeleteFileLoading } = this.state;
    return (
      <CommonDeleteDialogue
        open={isOpenDeleteDialog}
        title={"Are you sure you want to delete?"}
        body={"Please confirm you want to delete file."}
        deleteBtnText={"Delete file"}
        cancelBtnText={"Cancel"}
        handleCancel={this.handleCloseDeleteDialog}
        handleDelete={this.handleDeleteFile}
        isLoading={isDeleteFileLoading}
      />
    );
  };

  renderBulkUploadingDialog = () => {
    const { isOpenUploadDialog } = this.state;
    const { navigation, classes } = this.props;
    return (
      <BulkUploadDashboardDialog
        id="BulkUploadGeneralTabDialog"
        isDialogOpen={isOpenUploadDialog}
        onCloseDialog={this.onCloseUploadDialog}
        selectWorkspace={true}
        navigation={navigation}
        classes={classes}
      />
    );
  };

  renderFilePreviewDialog = () => {
    const { isOpenPreviewDialog, showAiProcessingForm, dataFiles } = this.state;
    const { navigation, classes } = this.props;
    return (
      <DashboardPreviewFile
        id="DashboardPreviewFile"
        isDialogOpen={isOpenPreviewDialog}
        onCloseDialog={this.handleClosePreviewDialog}
        classes={classes}
        files={dataFiles?.files ?? []}
        navigation={navigation}
        showAiProcessingForm={showAiProcessingForm}
        workspaceSelected={true}
        selectedAiProcessing={
          dataFiles?.uploadChoice as
            | UploadOptions.AIProcessing
            | UploadOptions.GreekLegalDocument
        }
      />
    );
  };

  renderSnackbar = () => {
    const { snackbarOpen, snackbarMessage, snackbarServerity } = this.state;
    return (
      <CommonSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={this.handleCloseSnackbar}
        severity={snackbarServerity}
      />
    );
  };

  renderLoader() {
    const { mainLoading } = this.state;
    return mainLoading ? <Loader loading={mainLoading} /> : null;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      fileLists,
      actionMenuAnchor,
      selectedRow,
      isOpenUploadDialog,
      replaceFileDialogOpen,
      isOpenPreviewDialog,
      snackbarOpen,
      mainLoading,
      totalResults,
    } = this.state;
    const open = Boolean(actionMenuAnchor);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <GeneralTabViewWrapper>
          {mainLoading && this.renderLoader()}
          <Box>
            <DragAndDropBox onBrowse={this.handleOpenUploadModal} />
          </Box>
          <Box className="table-wrapper">
            <TableWrapper>
              <TableContainer component={Paper}>
                <Table aria-label="files table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{totalResults} Uploaded files</TableCell>
                      <TableCell>Date/Time</TableCell>
                      <TableCell>Uploaded by</TableCell>
                      <TableCell style={{ width: 50 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderBaseonCondition(
                      this.state.isFetchingData,
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>,
                      <>
                        {renderBaseonCondition(
                          fileLists.length === 0,
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{ textAlign: "center" }}
                            >
                              No Data found
                            </TableCell>
                          </TableRow>,
                          <>
                            {fileLists.map((file, index) => {
                              return (
                                <TableRow key={file.id}>
                                  <TableCell>
                                    <TableFileView
                                      extension={getFileExtension(
                                        file.attributes.files[0].filename
                                      )}
                                      fileName={
                                        file.attributes.files[0].filename
                                      }
                                      fileTime={moment(
                                        new Date(
                                          file.attributes.files[0].updated_at
                                        )
                                      ).fromNow()}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography className="date-time">
                                      {moment(
                                        file.attributes.files[0].created_at
                                      ).format("MM/DD/YY HH:mm")}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Box className="user-cell">
                                      <Avatar
                                        alt={file.attributes.uploaded_by}
                                        src={""}
                                        className="avatar-icon"
                                      />
                                      <Typography className="user-name">
                                        {file.attributes.uploaded_by ? file.attributes.uploaded_by : "---"}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      data-test-id={`more-menu-button-${file.id}`}
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        this.handleOpenActionMenu(event, file)
                                      }
                                      className={`file-action-btn ${
                                        open &&
                                        String(index) === selectedRow?.id
                                          ? "file-action-btn-active"
                                          : ""
                                      }`}
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {renderBaseonCondition(
                this.state.totalPages > 1,
                <Box className="pagination-box">
                  <StyledPagination
                    totalPages={this.state.totalPages}
                    currentPage={this.state.currentPage}
                    handleChangePage={this.handleChangePage}
                    startIndex={getPaginationIndex(
                      this.state.perPage,
                      this.state.currentPage,
                      this.state.totalResults,
                      "start"
                    )}
                    endIndex={getPaginationIndex(
                      this.state.perPage,
                      this.state.currentPage,
                      this.state.totalResults,
                      "end"
                    )}
                    totalResults={this.state.totalResults}
                  />
                </Box>,
                <></>
              )}
            </TableWrapper>
          </Box>
          {this.actionMenu()}
          {replaceFileDialogOpen && this.replaceFileDialog()}
          {this.renderDeleteDialog()}
          {isOpenUploadDialog && this.renderBulkUploadingDialog()}
          {isOpenPreviewDialog && this.renderFilePreviewDialog()}
          {snackbarOpen && this.renderSnackbar()}
        </GeneralTabViewWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const GeneralTabViewWrapper = styled(Box)({
  padding: "20px 16px",
  "& .table-wrapper": {
    margin: "20px 0 0 0",
  },
  "& .MuiCircularProgress-colorPrimary": {
    color: "#684EF3",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    boxShadow: "0px 4px 6px -2px #684EF333, 0px 12px 16px -4px #684EF333",
    border: `1px solid ${colors().tableBorder}`,
    minWidth: "148px",
    maxWidth: "148px",
    marginTop: 4,
  },
  "& .MuiListItem-button": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingTop: "9px",
    paddingBottom: "9px",
    "&:hover": {
      backgroundColor: "#F4F3FB",
    },
    "& .menu-icon": {
      fontSize: "18px",
    },
    "& .menu-item": {
      fontFamily: "DM Sans",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 400,
      color: colors().darkText,
    },
    "& .delete-menu-item": {
      color: colors().maximumRed,
    },
    "& .delete-menu-icon": {
      color: colors().maximumRed,
    },
  },
});

const TableWrapper = styled(Box)({
  width: "100%",
  fontFamily: "DM Sans",
  "& .MuiTableContainer-root": {
    boxShadow: "none",
  },
  "& .pagination-box": {
    padding: "6px 0 0 0",
  },
  "& .MuiTableCell-root": {
    fontFamily: "DM Sans",
    borderBottom: `1px solid ${colors().tableBorder}`,
    padding: "16px 0",
  },
  "& .MuiTableCell-head": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    color: colors().darkText,
  },
  "& .date-time": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: colors().grayText,
  },
  "& .user-cell": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  "& .avatar-icon": {
    width: 32,
    height: 32,
  },
  "& .user-name": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: colors().darkText,
  },
  "& .file-action-btn": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    minWidth: "24px",
    minHeight: "24px",
    padding: 3,
    color: colors().darkText,
  },
  "& .file-action-btn-active": {
    background: colors().darkPurple,
  },
});
export default withStyles(dashboardStyles)(AiQueryHubFilesTab);
// Customizable Area End
