import React from "react";
import { Box, styled, Typography } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import { fileIcon } from "./assets";

interface Props {
  extension: string;
  fileName: string;
  fileTime: string;
}

const TableFileView = (props: Props) => {
  const { extension, fileName, fileTime } = props;
  return (
    <FileView>
      <Box className="fileIconMain">
        <img src={fileIcon} alt="fileIcon" width={38} height={38} />
        <span className="file-extension">{extension}</span>
      </Box>
      <Box>
        <Typography className="file-name">{fileName}</Typography>
        <Typography className="file-time">{fileTime}</Typography>
      </Box>
    </FileView>
  );
};

const FileView = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 6,
  "& .fileIconMain": {
    position: "relative",
    width: 48,
    height: 48,
  },
  "& .file-extension": {
    position: "absolute",
    bottom: 3,
    right: 3,
    padding: "3px 4px",
    backgroundColor: colors().cultured,
    fontSize: "9px",
    lineHeight: "9px",
    fontWeight: 700,
    color: colors().majorelleBlue,
    textTransform: "uppercase",
    borderRadius: 4,
    fontFamily: "DM Sans",
  },
  "& .file-name": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    color: colors().darkText,
  },
  "& .file-time": {
    fontFamily: "DM Sans",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    color: colors().grayText,
  },
});

export default TableFileView;
