// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";

export enum ShareMethod {
  Chat = "chat",
  Email = "email",
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openChatBot: boolean;
  activeTab: number;
  anchorEl: HTMLButtonElement | null;
  openDowloadSettings: boolean;
  openShare: boolean;
  openNewQueryDialog: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AiQueryHubController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openChatBot: true,
      activeTab: 0,
      anchorEl: null,
      openDowloadSettings: false,
      openShare: false,
      openNewQueryDialog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area End
  }

  // Customizable Area Start

  handleDrawerOpen = () => {
    this.setState({ openChatBot: true });
  };

  handleDrawerClose = () => {
    this.setState({ openChatBot: false });
  };

  handleTabChange = (_: React.ChangeEvent<{}>, value: number) => {
    this.setState({ activeTab: value });
  };

  openMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMoreMenu = () => {
    this.setState({ anchorEl: null });
  };

  toggleDownloadSettings = () =>
    this.setState((prevState) => ({
      openDowloadSettings: !prevState.openDowloadSettings,
    }));

  toggleShare = () =>
    this.setState((prevState) => ({
      openShare: !prevState.openShare,
    }));

  downloadValidationSchema = Yup.object({
    fileName: Yup.string().required("File name is required"),
    resultVersion: Yup.string().required("Select a version"),
    fileFormat: Yup.string().required("Select a format"),
  });

  shareValidationSchema = Yup.object({
    shareMethod: Yup.string().required("Select a contact method"),
    email: Yup.string().when("chooseMethod", {
      is: ShareMethod.Email,
      then: Yup.string().email("Invalid email").required("Email is required"),
    }),
    chatOption: Yup.array().when("contactMethod", {
      is: ShareMethod.Chat,
      then: Yup.array()
        .min(1, "At least one chat option is required")
        .required("Chat option is required"),
    }),
    resultVersion: Yup.string().required("Select a version"),
    fileFormat: Yup.string().required("Select a format"),
  });

  defaultShareValues = {
    shareMethod: ShareMethod.Chat,
    chatOption: ["Top designers", "LT Workspace"],
    email: "",
    resultVersion: "v1",
    fileFormat: "pdf",
  };

  toggleNewQueryDialog = () =>
    this.setState((prevState) => ({
      openNewQueryDialog: !prevState.openNewQueryDialog,
    }));

  // Customizable Area End
}
